// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-a-11-y-js": () => import("/opt/build/repo/src/pages/resume/a11y.js" /* webpackChunkName: "component---src-pages-resume-a-11-y-js" */),
  "component---src-pages-resume-fotv-js": () => import("/opt/build/repo/src/pages/resume/fotv.js" /* webpackChunkName: "component---src-pages-resume-fotv-js" */),
  "component---src-pages-resume-index-js": () => import("/opt/build/repo/src/pages/resume/index.js" /* webpackChunkName: "component---src-pages-resume-index-js" */),
  "component---src-pages-resume-inspiren-js": () => import("/opt/build/repo/src/pages/resume/inspiren.js" /* webpackChunkName: "component---src-pages-resume-inspiren-js" */),
  "component---src-pages-resume-resume-alberta-js": () => import("/opt/build/repo/src/pages/resume/resume-alberta.js" /* webpackChunkName: "component---src-pages-resume-resume-alberta-js" */),
  "component---src-pages-resume-the-star-js": () => import("/opt/build/repo/src/pages/resume/the-star.js" /* webpackChunkName: "component---src-pages-resume-the-star-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

